import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom';
import Select from 'react-select';
export default function AppData({ setUserData }) {

    const [AppData, setAppData] = useState([])
    const [LoadingAppData, setLoadingAppData] = useState(false)

    const [AddMode, setAddMode] = useState(false)
    const [LoadingAdd, setLoadingAdd] = useState(false)


    //handel gets
    async function getAppData() {
        setLoadingAppData(true)
        await axios(`${process.env.REACT_APP_API}/getAppData`).catch((err) => {
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
                setLoadingAppData(false)
            } else {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
                setLoadingAppData(false)
            }
        }).then((res) => {
            setAppData(res?.data?.data[0])
            setLoadingAppData(false)
        })
    }

    //handel add 
    let AddvalidationSchema = Yup.object({
        telegramPage: Yup.string().required('telegramPage is required'),
        appID: Yup.string().required('appID is required'),
        bannerID: Yup.string().required('bannerID is required'),
        interstitialID: Yup.string().required('interstitialID is required')

    })

    let formik2 = useFormik({
        initialValues: {
            telegramPage: "",
            appID: "",
            bannerID: "",
            interstitialID: ""
        },
        onSubmit: handleAdd,
        validationSchema: AddvalidationSchema
    })

    async function handleAdd(values) {
        setLoadingAdd(true)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios.put(`${process.env.REACT_APP_API}/updateAppData/${AppData._id}`, values, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingAdd(false)
                return toast.error(err?.response?.data?.message)
            } else {
                setLoadingAdd(false)
                return toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getAppData()
            setLoadingAdd(false)
            setAddMode(false)
            formik2.resetForm()
        })
    }
    async function handleAddData() {
        formik2.setValues({
            telegramPage: AppData.telegramPage,
            appID: AppData.appID,
            bannerID: AppData.bannerID,
            interstitialID: AppData.interstitialID
        })
        setAddMode(true)
    }




    useEffect(() => {
        getAppData()
    }, [])


    return <>
        {AddMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik2.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '> App Data</h3>

                        <label for="telegramPage" class="form-label mainFont">telegramPage</label>
                        <input className='form-control' type="text" name='telegramPage' id='telegramPage' value={formik2.values.telegramPage} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.telegramPage && formik2.touched.telegramPage ? <div className='form-text text-danger'>{formik2.errors.telegramPage}</div> : null}

                        <label for="appID" class="form-label mainFont">appID</label>
                        <input className='form-control' type="text" name='appID' id='appID' value={formik2.values.appID} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.appID && formik2.touched.appID ? <div className='form-text text-danger'>{formik2.errors.appID}</div> : null}

                        <label for="bannerID" class="form-label mainFont">bannerID</label>
                        <input className='form-control' type="text" name='bannerID' id='bannerID' value={formik2.values.bannerID} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.bannerID && formik2.touched.bannerID ? <div className='form-text text-danger'>{formik2.errors.bannerID}</div> : null}

                        <label for="interstitialID" class="form-label mainFont">interstitialID</label>
                        <input className='form-control' type="text" name='interstitialID' id='interstitialID' value={formik2.values.interstitialID} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.interstitialID && formik2.touched.interstitialID ? <div className='form-text text-danger'>{formik2.errors.interstitialID}</div> : null}



                        <div className='row my-2 g-3'>
                            {LoadingAdd ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik2.isValid && formik2.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Update</button>
                            }
                            <button onClick={() => {
                                formik2.resetForm()
                                setAddMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}



        <div className='container pt-5 bg-white my-5 shadow-lg'>
            <h2 className='text-center mainFont h1'>App Data</h2>

            <div className='p-5 '>
                <button onClick={() => {
                    handleAddData()
                }} className='btn mainBtn rounded-pill my-2 mx-auto col-12  '>Update App Data</button>
                {LoadingAppData ? <div className='col-12 text-center my-5 py-5'>
                    <i className='fa fa-spin fa-spinner fa-3x text-success'></i>
                </div> : <>
                    <div className='table-responsive'>
                        <table class="table table-striped rounded-3 table-hover text-center  my-5">
                            <tbody >
                                <tr >
                                    <th scope="col" className='mainFont'>telegram Link</th>
                                    <td scope="col" className='mainFont'>{AppData.telegramPage}</td>
                                </tr>
                                <tr >
                                    <th scope="col" className='mainFont'>app ID</th>
                                    <td scope="col" className='mainFont'>{AppData.appID}</td>
                                </tr>
                                <tr >
                                    <th scope="col" className='mainFont'>banner ID </th>
                                    <td scope="col" className='mainFont'>{AppData.bannerID}</td>
                                </tr>
                                <tr >
                                    <th scope="col" className='mainFont'>interstitial ID </th>
                                    <td scope="col" className='mainFont'>{AppData.interstitialID}</td>
                                </tr>
                            </tbody>

                        </table>

                        {/* <table class="table table-striped  table-hover mx-auto text-center my-5 ">
                            <thead >
                                <tr >
                                    <th scope="col" className='mainFont'>telegramPage</th>
                                    <th scope="col" className='mainFont'>appID</th>
                                    <th scope="col" className='mainFont'>bannerID</th>
                                    <th scope="col" className='mainFont'>interstitialID</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr className='align-baseline'>
                                    <td className='mainFont'>{AppData.telegramPage}</td>
                                    <td className='mainFont'>{AppData.appID}</td>
                                    <td className='mainFont'>{AppData.bannerID}</td>
                                    <td className='mainFont'>{AppData.interstitialID}</td>
                                </tr>
                            </tbody>
                        </table> */}
                    </div>


                </>
                }
            </div>
        </div>
    </>
}
