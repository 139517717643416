// src/StandaloneDownload.js
import axios from 'axios';
import React from 'react';
import img from '../../168843.png'

function StandaloneDownload() {

    async function handleDownloadApk() {
        await axios.get(`${process.env.REACT_APP_API}/download-apk`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'noah.apk'; // File name to save
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch(error => console.error('Error downloading APK:', error));
    }

    return <>
        <div className='w-100 vh-100 row justify-content-center align-items-center ps-4'>
            <div className='col-md-3 text-center'>
                <img src={img} alt="logo" className="img-fluid " />
                <h1 className='mainFont mt-2 '>NoOoH IPTV</h1>
                <button onClick={() => {
                    handleDownloadApk()
                }} className="btn mainBtn col-6 rounded-pill shadow-lg">
                    Download APK
                </button></div>
        </div>
    </>
}

export default StandaloneDownload;
