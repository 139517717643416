import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom';
import Select from 'react-select';
export default function Categorys({ setUserData }) {
    const [Categories, setCategories] = useState([])

    const [LoadingCategories, setLoadingCategories] = useState(false)



    const [UpdateMode, setUpdateMode] = useState(false)
    const [LoadingUpdate, setLoadingUpdate] = useState(false)

    const [DetailsMode, setDetailsMode] = useState(false)
    const [CategoryData, setCategoryData] = useState({})

    const [AddMode, setAddMode] = useState(false)
    const [LoadingAdd, setLoadingAdd] = useState(false)
    const [typeOptions, settypeOptions] = useState([{ value: 'channel', label: "Channel" }, { value: 'movie', label: "Movie" }, { value: 'series', label: "Series" }])


    const [UpdateImageMode, setUpdateImageMode] = useState(false)
    const [LoadingUpdateImage, setLoadingUpdateImage] = useState(false)

    //handel gets
    async function getCategories() {
        setLoadingCategories(true)
        await axios(`${process.env.REACT_APP_API}/category/getcategories`).catch((err) => {
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
                setLoadingCategories(false)
            } else {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
                setLoadingCategories(false)
            }
        }).then((res) => {
            setCategories(res?.data?.data)
            setLoadingCategories(false)

        })
    }



    //handel add 
    let AddvalidationSchema = Yup.object({
        title: Yup.string().required('title is required'),
        type: Yup.string().required('type is required'),
        CategoryImage: Yup.mixed().required('Category Image is required')
    })

    let formik2 = useFormik({
        initialValues: {
            title: "",
            type: "",
            CategoryImage: ""
        },
        onSubmit: handleAdd,
        validationSchema: AddvalidationSchema
    })


    async function handleAdd(values) {
        try {
            setLoadingAdd(true);
            let formData = new FormData();
            formData.append('title', values.title);
            formData.append('type', values.type);
            formData.append('image', values.CategoryImage);

            let token = localStorage.getItem('token');
            let headers = {
                Authorization: `Bearer ${token}`
            };

            const res = await axios.post(
                `${process.env.REACT_APP_API}/category/addCategory`,
                formData,
                { headers }
            );

            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message);
            getCategories();
            setAddMode(false);
            formik2.resetForm();
        } catch (err) {
            console.log(err);

            if (err?.response?.status === 401) {
                localStorage.clear();
                setUserData(null);
            }

            const errorMessage = err?.response?.data?.message || 'An unexpected error occurred';
            toast.error(errorMessage);
        } finally {
            setLoadingAdd(false);
        }
    }


    //handel update 

    let validationSchema = Yup.object({
        title: Yup.string().required('title is required'),
    })

    let formik = useFormik({
        initialValues: {
            _id: "",
            title: ""
        },
        onSubmit: handleUpdate,
        validationSchema
    })

    async function handleUpdate(values) {
        console.log(values);
        setLoadingUpdate(true)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.patch(`${process.env.REACT_APP_API}/category/updateCategoryTitle/${values._id}`, {
            title: values.title,
        }, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdate(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdate(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getCategories()
            setLoadingUpdate(false)
            setUpdateMode(false)


        })
    }

    async function handelUpdatedData(category) {
        formik.setValues({
            _id: category._id,
            title: category.title
        })
        setUpdateMode(true)

    }

    //handel update image
    let UpdateImageValidationSchema = Yup.object({
        CategoryImage: Yup.mixed().required('Category Image is required')
    })
    let formik3 = useFormik({
        initialValues: {
            _id: "",
            CategoryImage: ""
        },
        onSubmit: handleUpdateImage,
        validationSchema: UpdateImageValidationSchema
    })
    async function handleUpdateImage(values) {
        setLoadingUpdateImage(true)

        let formData = new FormData()
        formData.append('image', values.CategoryImage)

        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios.patch(`${process.env.REACT_APP_API}/category/updateCategoryImage/${values._id}`, formData, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdateImage(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdateImage(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getCategories()
            setLoadingUpdateImage(false)
            setUpdateImageMode(false)
            formik3.resetForm()
        })
    }
    //handel delete 
    async function deleteCategory(_id) {
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.delete(`${process.env.REACT_APP_API}/category/deleteCategory/${_id}`, { headers }).catch((err) => {
            if (err?.response?.status == 401) {
                console.log(err);
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
            } else {
                console.log(err);
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            toast.success(res?.data?.message)
            getCategories()
        })
    }

    useEffect(() => {
        getCategories()
    }, [])


    return <>
        {AddMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik2.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Add category</h3>

                        <label for="title" class="form-label mainFont">name</label>
                        <input className='form-control' type="text" name='title' id='title' value={formik2.values.title} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.title && formik2.touched.title ? <div className='form-text text-danger'>{formik2.errors.title}</div> : null}


                        <label for="type" class="form-label mainFont mt-1">type</label>

                        <Select
                            id="type"
                            name="type"
                            options={typeOptions}
                            value={typeOptions.find(option => option.value === formik2.values.type)}
                            onChange={(selectedOption) => {
                                formik2.setFieldValue('type', selectedOption.value);
                            }}
                            onBlur={formik2.handleBlur}
                        />



                        <label for="CategoryImage" class="form-label mainFont mt-1">Channel Image</label>
                        <input
                            onChange={(event) => formik2.setFieldValue('CategoryImage', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='CategoryImage'
                            id='CategoryImage'
                            onBlur={formik2.handleBlur} />
                        {formik2.errors.CategoryImage && formik2.touched.CategoryImage ? <div className='form-text text-danger'>{formik2.errors.CategoryImage}</div> : null}


                        <div className='row my-2 g-3'>
                            {LoadingAdd ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik2.isValid && formik2.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Add</button>
                            }
                            <button onClick={() => {
                                formik2.resetForm()
                                setAddMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateImageMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik3.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Update category image</h3>

                        <label for="CategoryImage" class="form-label mainFont mt-2">Channel Image</label>
                        <input
                            onChange={(event) => formik3.setFieldValue('CategoryImage', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='CategoryImage'
                            id='CategoryImage'
                            onBlur={formik3.handleBlur} />
                        {formik3.errors.CategoryImage && formik3.touched.CategoryImage ? <div className='form-text text-danger'>{formik3.errors.CategoryImage}</div> : null}


                        <div className='row my-2 g-3'>
                            {LoadingUpdateImage ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik3.isValid && formik3.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Update</button>
                            }
                            <button onClick={() => {
                                formik3.resetForm()
                                setUpdateImageMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Update Category</h3>

                        <label for="title" class="form-label mainFont">name</label>
                        <input className='form-control' type="text" name='title' id='title' value={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.title && formik.touched.title ? <div className='form-text text-danger'>{formik.errors.title}</div> : null}

                        <div className='row my-2 g-3'>
                            {LoadingUpdate ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>update</button>
                            }
                            <button onClick={() => { setUpdateMode(false) }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {DetailsMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <>
                        <div className="w-100 my-5 bg-light  p-5 rounded-3 shadow text-center overflow-auto scrollable-content " style={{ maxHeight: '90vh' }}>
                            <h3 className='text-center mainFont '>Category Details</h3>
                            <div className='row justify-content-center align-items-center p-4' >
                                <div className='col-md-6 py-3'>
                                    <img src={CategoryData.image.OpenImage} className="bg-body-secondary p-1  img-fluid shadow-lg fixed-img " alt="..." />
                                    <h5 className="card-title">{CategoryData.title}</h5>
                                    <p className="card-text">{CategoryData.type}</p>
                                </div>
                                <button onClick={() => { setDetailsMode(false) }} className='btn mx-auto mainBtn col-12 rounded-pill'>Close</button>
                            </div>
                        </div>
                    </>
                </div>
            </div>
            : null}

        <div className='container pt-5 bg-white my-5 shadow-lg'>
            <h2 className='text-center mainFont h1'>Category</h2>

            <div className='p-5 '>
                <button onClick={() => {
                    setAddMode(true)
                }} className='btn mainBtn rounded-pill my-2 mx-auto col-12  '>Add Category</button>
                {LoadingCategories ? <div className='col-12 text-center my-5 py-5'>
                    <i className='fa fa-spin fa-spinner fa-3x text-success'></i>
                </div> : <>
                    {Categories?.length != 0 ? <div className='table-responsive py-5'>
                        <div className="py-5">
                            <table class="table table-striped  table-hover mx-auto text-center my-5 ">
                                <thead >
                                    <tr >
                                        <th scope="col" className='mainFont' >#</th>
                                        <th scope="col" className='mainFont'>title</th>
                                        <th scope="col" className='mainFont'>type</th>

                                        <th scope="col" className='mainFont'>Acthions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {Categories?.map((category, index) => {
                                        return <tr className='align-baseline'>
                                            <th scope="row" className='mainFont'>{index + 1}</th>
                                            <td className='mainFont'>{category.title}</td>
                                            <td className='mainFont'>{category.type}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button class="btn mainIcon  " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="fa-solid fa-list fa-0 mainFont"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li onClick={() => { deleteCategory(category._id) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-trash-can me-2"></i>delete</li>
                                                        <li onClick={() => { handelUpdatedData(category) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update</li>
                                                        <li onClick={() => {
                                                            formik3.setFieldValue("_id", category._id)
                                                            setUpdateImageMode(true)
                                                        }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update Image</li>

                                                        <li onClick={() => {
                                                            setCategoryData(category)
                                                            setDetailsMode(true)
                                                        }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-eye me-2"></i>Details</li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div> : <div className='col-12 text-center my-5 py-5'>
                        <h3 className='mainFont'>Don't have Categories</h3>

                    </div>}


                </>
                }
            </div>
        </div>
    </>
}
