import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';

export default function Series({ setUserData }) {
    const navigate = useNavigate();
    const [LoadingSeries, setLoadingSeries] = useState(false)
    const [Series, setSeries] = useState([])

    const [Categories, setCategories] = useState([])

    const [UpdateMode, setUpdateMode] = useState(false)
    const [LoadingUpdate, setLoadingUpdate] = useState(false)


    const [AddMode, setAddMode] = useState(false)
    const [LoadingAdd, setLoadingAdd] = useState(false)


    const [UpdateImageMode, setUpdateImageMode] = useState(false)
    const [LoadingUpdateImage, setLoadingUpdateImage] = useState(false)

    const [langOptions, setlangOptions] = useState([{ value: 'ar', label: "Arabic" }, { value: 'en', label: "English" }])
    const [categoryOptions, setcategoryOptions] = useState([])


    const [TrendingMode, setTrendingMode] = useState(false)
    const [LoadingTrending, setLoadingTrending] = useState(false)
    const [SeriesOptions, setSeriesOptions] = useState([])




    //handel gets
    async function getSeries() {
        setLoadingSeries(true)
        await axios(`${process.env.REACT_APP_API}/series/getSeries`).catch((err) => {
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
                setLoadingSeries(false)
            } else {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
                setLoadingSeries(false)
            }
        }).then((res) => {
            setSeries(res?.data?.data)
            setSeriesOptions(res?.data?.data?.map((cate) => ({
                value: cate._id,
                label: cate.title,
            })))
            setLoadingSeries(false)

        })
    }
    async function getSeriesCategory() {

        await axios(`${process.env.REACT_APP_API}/category/getcategories/series`).catch((err) => {
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
            } else {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            setcategoryOptions(res?.data?.data?.map((cate) => ({
                value: cate._id,
                label: cate.title,
            })))
        })
    }

    //handel add 
    let AddvalidationSchema = Yup.object({
        title: Yup.string().required('title is required'),
        description: Yup.string().required('description is required'),
        releaseDate: Yup.string().required('releaseDate is required'),
        category: Yup.string().required('category is required'),
        rating: Yup.number().required('rating is required'),
        lang: Yup.string().required('lang is required'),
        SeriesImage: Yup.mixed().required('Series SeriesImage is required')
    })

    let formik2 = useFormik({
        initialValues: {
            title: "",
            description: "",
            releaseDate: "",
            rating: "",
            category: "",
            lang: "",
            SeriesImage: ""
        },
        onSubmit: handleAdd,
        validationSchema: AddvalidationSchema
    })

    async function handleAdd(values) {
        try {
            setLoadingAdd(true);

            let formData = new FormData();
           


            formData.append('title', values.title);
            formData.append('description', values.description);
            formData.append('releaseDate', values.releaseDate);
            formData.append('rating', values.rating);
            formData.append('lang', values.lang);
            formData.append('category', values.category);
            formData.append('image', values.SeriesImage);

            let token = localStorage.getItem('token');
            let headers = {
                Authorization: `Bearer ${token}`
            };

            const res = await axios.post(
                `${process.env.REACT_APP_API}/series/addSeries`,
                formData,
                { headers }
            );

            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message);
            getSeries();
            setAddMode(false);
            formik2.resetForm();
        } catch (err) {
            console.log(err);

            if (err?.response?.status === 401) {
                localStorage.clear();
                setUserData(null);
            }

            const errorMessage = err?.response?.data?.message || 'An unexpected error occurred';
            toast.error(errorMessage);
        } finally {
            setLoadingAdd(false);
        }
    }


    //handel update 

    let validationSchema = Yup.object({
        title: Yup.string().required('title is required'),
        description: Yup.string().required('description is required'),
        releaseDate: Yup.string().required('releaseDate is required'),
        rating: Yup.number().required('rating is required'),
        category: Yup.string().required('category is required'),
        lang: Yup.string().required('lang is required'),
    })

    let formik = useFormik({
        initialValues: {
            _id: "",
            title: "",
            description: "",
            releaseDate: "",
            rating: "",
            category: "",
            lang: "",
        },
        onSubmit: handleUpdate,
        validationSchema
    })

    async function handleUpdate(values) {
        console.log(values);
        setLoadingUpdate(true)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.patch(`${process.env.REACT_APP_API}/series/updateSeries/${values._id}`, {
            title: values.title,
            category: values.category,
            lang: values.lang,
            description: values.description,
            releaseDate: values.releaseDate,
            rating: values.rating,
        }, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdate(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdate(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getSeries()
            setLoadingUpdate(false)
            setUpdateMode(false)


        })
    }

    async function handelUpdatedData(Series) {
        getSeriesCategory()
        formik.setValues({
            _id: Series._id,
            title: Series.title,
            category: Series.category._id,
            lang: Series.lang,
            description: Series.description,
            releaseDate: Series.releaseDate,
            rating: Series.rating
        })
        setUpdateMode(true)

    }

    //handel update image
    let UpdateImageValidationSchema = Yup.object({
        SeriesImage: Yup.mixed().required('Series Image is required')
    })
    let formik3 = useFormik({
        initialValues: {
            _id: "",
            SeriesImage: ""
        },
        onSubmit: handleUpdateImage,
        validationSchema: UpdateImageValidationSchema
    })
    async function handleUpdateImage(values) {
        setLoadingUpdateImage(true)

        let formData = new FormData()
        formData.append('image', values.SeriesImage)

        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios.patch(`${process.env.REACT_APP_API}/series/updateSeriesImage/${values._id}`, formData, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdateImage(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdateImage(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getSeries()
            setLoadingUpdateImage(false)
            setUpdateImageMode(false)
            formik3.resetForm()
        })
    }

    //handleTrending
    let TrendinValidationSchema = Yup.object({
        Series: Yup.array().length(5, 'Series length must be 5').required('Series is required')
    })
    let formik4 = useFormik({
        initialValues: {
            Series: []
        },
        onSubmit: handleTrending,
        validationSchema: TrendinValidationSchema
    })
    async function handleTrending(values) {
        console.log(values);

        setLoadingTrending(true)

        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios.patch(`${process.env.REACT_APP_API}/series/addSeriesToTrend`, values, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingTrending(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingTrending(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getSeries()
            setLoadingTrending(false)
            setTrendingMode(false)
            formik4.resetForm()
        })
    }

    //handel delete 
    async function deleteSeries(_id) {
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.delete(`${process.env.REACT_APP_API}/series/deleteSeries/${_id}`, { headers }).catch((err) => {
            if (err?.response?.status == 401) {
                console.log(err);
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
            } else {
                console.log(err);
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            toast.success(res?.data?.message)
            getSeries()
        })
    }

    useEffect(() => {
        getSeries()
    }, [])


    return <>
        {AddMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik2.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow overflow-auto scrollable-content' style={{ maxHeight: '90vh' }}>
                        <h3 className='text-center mainFont '>Add Series</h3>

                        <label for="title" class="form-label mainFont">name</label>
                        <input className='form-control' type="text" name='title' id='title' value={formik2.values.title} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.title && formik2.touched.title ? <div className='form-text text-danger'>{formik2.errors.title}</div> : null}


                        <label for="description" class="form-label mainFont mt-2 ">description</label>
                        <input className='form-control' type="text" name='description' id='description' value={formik2.values.description} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.description && formik2.touched.description ? <div className='form-text text-danger'>{formik2.errors.description}</div> : null}


                        <label for="releaseDate" class="form-label mainFont mt-2 ">Year</label>
                        <input className='form-control' type="number" name='releaseDate' id='releaseDate' value={formik2.values.releaseDate} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.releaseDate && formik2.touched.releaseDate ? <div className='form-text text-danger'>{formik2.errors.releaseDate}</div> : null}


                        <label for="rating" class="form-label mainFont mt-2 ">rating</label>
                        <input className='form-control' type="number" name='rating' id='rating' value={formik2.values.rating} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.rating && formik2.touched.rating ? <div className='form-text text-danger'>{formik2.errors.rating}</div> : null}


                        <label for="lang" class="form-label mainFont mt-2">Language</label>

                        <Select
                            id="lang"
                            name="lang"
                            options={langOptions}
                            value={langOptions.find(option => option.value === formik2.values.lang)}
                            onChange={(selectedOption) => {
                                formik2.setFieldValue('lang', selectedOption.value);
                            }}
                            onBlur={formik2.handleBlur}
                        />





                        <label for="category" class="form-label mainFont mt-2">Category</label>

                        <Select
                            id="category"
                            name="category"
                            options={categoryOptions}
                            value={categoryOptions.find(option => option.value === formik2.values.category)}
                            onChange={(selectedOption) => {
                                formik2.setFieldValue('category', selectedOption.value);
                            }}
                            onBlur={formik2.handleBlur}
                        />


                        <label for="SeriesImage" class="form-label mainFont mt-2">Movie Image</label>
                        <input
                            onChange={(event) => formik2.setFieldValue('SeriesImage', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='SeriesImage'
                            id='SeriesImage'
                            onBlur={formik2.handleBlur} />
                        {formik2.errors.SeriesImage && formik2.touched.SeriesImage ? <div className='form-text text-danger'>{formik2.errors.SeriesImage}</div> : null}


                        <div className='row my-2 g-3'>
                            {LoadingAdd ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik2.isValid && formik2.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Add</button>
                            }
                            <button onClick={() => {
                                formik2.resetForm()
                                setAddMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateImageMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik3.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow overflow-auto scrollable-content' style={{ maxHeight: '90vh' }}>
                        <h3 className='text-center mainFont '>Update Series Image</h3>

                        <label for="SeriesImage" class="form-label mainFont mt-2">Series Image</label>
                        <input
                            onChange={(event) => formik3.setFieldValue('SeriesImage', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='SeriesImage'
                            id='SeriesImage'
                            onBlur={formik3.handleBlur} />
                        {formik3.errors.SeriesImage && formik3.touched.SeriesImage ? <div className='form-text text-danger'>{formik3.errors.SeriesImage}</div> : null}


                        <div className='row my-2 g-3'>
                            {LoadingUpdateImage ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik3.isValid && formik3.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Update</button>
                            }
                            <button onClick={() => {
                                formik3.resetForm()
                                setUpdateImageMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow overflow-auto scrollable-content' style={{ maxHeight: '90vh' }}>
                        <h3 className='text-center mainFont '>Update Series</h3>

                        <label for="title" class="form-label mainFont">name</label>
                        <input className='form-control' type="text" name='title' id='title' value={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.title && formik.touched.title ? <div className='form-text text-danger'>{formik.errors.title}</div> : null}


                        <label for="description" class="form-label mainFont mt-2 ">description</label>
                        <input className='form-control' type="text" name='description' id='description' value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.description && formik.touched.description ? <div className='form-text text-danger'>{formik.errors.description}</div> : null}


                        <label for="releaseDate" class="form-label mainFont mt-2 ">Year</label>
                        <input className='form-control' type="number" name='releaseDate' id='releaseDate' value={formik.values.releaseDate} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.releaseDate && formik.touched.releaseDate ? <div className='form-text text-danger'>{formik.errors.releaseDate}</div> : null}

                        <label for="rating" class="form-label mainFont mt-2 ">rating</label>
                        <input className='form-control' type="number" name='rating' id='rating' value={formik.values.rating} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.rating && formik.touched.rating ? <div className='form-text text-danger'>{formik.errors.rating}</div> : null}



                        <label for="lang" class="form-label mainFont mt-2">Language</label>

                        <Select
                            id="lang"
                            name="lang"
                            options={langOptions}
                            value={langOptions.find(option => option.value === formik.values.lang)}
                            onChange={(selectedOption) => {
                                formik.setFieldValue('lang', selectedOption.value);
                            }}
                            onBlur={formik.handleBlur}
                        />





                        <label for="category" class="form-label mainFont mt-2">Category</label>

                        <Select
                            id="category"
                            name="category"
                            options={categoryOptions}
                            value={categoryOptions.find(option => option.value === formik.values.category)}
                            onChange={(selectedOption) => {
                                formik.setFieldValue('category', selectedOption.value);
                            }}
                            onBlur={formik.handleBlur}
                        />


                        <div className='row my-2 g-3'>
                            {LoadingUpdate ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>update</button>
                            }
                            <button onClick={() => { setUpdateMode(false) }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {TrendingMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik4.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow overflow-auto scrollable-content' style={{ maxHeight: '90vh' }}>
                        <h3 className='text-center mainFont '>Trending Series</h3>



                        <label for="Series" class="form-label mainFont mt-2">Select 5 Series</label>

                        <Select
                            id="Series"
                            name="Series"
                            options={SeriesOptions}
                            isMulti
                            value={SeriesOptions.filter(option => formik4.values.Series.includes(option.value))}
                            onChange={(selectedOptions) => {
                                const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                formik4.setFieldValue('Series', values);
                            }}
                            onBlur={formik4.handleBlur}
                            className=''
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuShouldScrollIntoView={true}
                            styles={{
                                menuList: (provided) => ({
                                    ...provided,
                                    maxHeight: '160px', // Adjust the height as needed
                                    overflowY: 'auto',
                                    WebkitOverflowScrolling: 'touch', // Ensures smooth scrolling on iOS devices
                                    touchAction: 'auto',
                                }),
                            }}
                        />

                        <div className='row my-2 g-3'>
                            {LoadingTrending ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik4.isValid && formik4.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Select</button>
                            }
                            <button onClick={() => { setTrendingMode(false) }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}



        <div className='container pt-5 bg-white my-5 shadow-lg'>
            <h2 className='text-center mainFont h1'>Series</h2>

            <div className='p-5 '>
                <button onClick={() => {
                    getSeriesCategory()
                    setAddMode(true)
                }} className='btn mainBtn rounded-pill my-2 mx-auto col-12  '>Add Series</button>
                <button onClick={() => {
                    setTrendingMode(true)
                }} className='btn mainBtn rounded-pill my-2 mx-auto col-12  '>Trending Series</button>
                {LoadingSeries ? <div className='col-12 text-center my-5 py-5'>
                    <i className='fa fa-spin fa-spinner fa-3x text-success'></i>
                </div> : <>
                    {Series?.length != 0 ? <div className='table-responsive py-5'>
                        <div className="py-5">
                            <table class="table table-striped  table-hover mx-auto text-center my-5 ">
                                <thead >
                                    <tr >
                                        <th scope="col" className='mainFont' >#</th>
                                        <th scope="col" className='mainFont'>name</th>
                                        <th scope="col" className='mainFont'>year</th>
                                        <th scope="col" className='mainFont'>rating</th>
                                        <th scope="col" className='mainFont'>lang</th>
                                        <th scope="col" className='mainFont'>category</th>
                                        <th scope="col" className='mainFont'>trending</th>
                                        <th scope="col" className='mainFont'>Acthions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {Series?.map((Series, index) => {
                                        return <tr className='align-baseline'>
                                            <th scope="row" className='mainFont'>{index + 1}</th>
                                            <td className='mainFont'>{Series.title}</td>
                                            <td className='mainFont'>{Series.releaseDate}</td>
                                            <td className='mainFont'>{Series.rating}</td>
                                            <td className='mainFont'>{Series.lang}</td>
                                            <td className='mainFont'>{Series?.category?.title}</td>
                                            {Series.trending ? <td className='mainFont'><span class="badge text-bg-success">True</span></td>
                                                : <td className='mainFont'><span class="badge text-bg-danger">False</span></td>}
                                            <td>
                                                <div class="dropdown">
                                                    <button class="btn mainIcon  " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="fa-solid fa-list fa-0 mainFont"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li onClick={() => { deleteSeries(Series._id) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-trash-can me-2"></i>delete</li>
                                                        <li onClick={() => { handelUpdatedData(Series) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update</li>
                                                        <li onClick={() => {
                                                            formik3.setFieldValue("_id", Series._id)
                                                            setUpdateImageMode(true)
                                                        }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update Image</li>

                                                        <li onClick={() => {
                                                            navigate(`/episodes/${Series._id}`)
                                                        }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-eye me-2"></i>Details</li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div> : <div className='col-12 text-center my-5 py-5'>
                        <h3 className='mainFont'>Don't have Series</h3>
                    </div>}


                </>
                }
            </div>

        </div>

    </>
}
