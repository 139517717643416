import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom';
import Select from 'react-select';
export default function Notification({ setUserData }) {

    const [Notification, setNotification] = useState([])
    const [LoadingNotification, setLoadingNotification] = useState(false)

    const [AddMode, setAddMode] = useState(false)
    const [LoadingAdd, setLoadingAdd] = useState(false)

    const [typeOptions, settypeOptions] = useState([{ value: 'channel', label: "Channel" }, { value: 'movie', label: "Movie" }, { value: 'series', label: "Series" }, { value: 'match', label: "Match" }, { value: 'home', label: "Home" }])

    //handel gets
    async function getNotification() {
        setLoadingNotification(true)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios(`${process.env.REACT_APP_API}/getNotifications`, { headers }).catch((err) => {
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
                setLoadingNotification(false)
            } else {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
                setLoadingNotification(false)
            }
        }).then((res) => {
            setNotification(res?.data?.data)
            setLoadingNotification(false)
        })
    }

    //handel add 
    let AddvalidationSchema = Yup.object({
        title: Yup.string().required('title is required'),
        body: Yup.string().required('body is required'),
        screen: Yup.string().required('screen is required')
    })

    let formik2 = useFormik({
        initialValues: {
            title: "",
            body: "",
            screen: ""
        },
        onSubmit: handleAdd,
        validationSchema: AddvalidationSchema
    })

    async function handleAdd(values) {
        setLoadingAdd(true)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios.post(`${process.env.REACT_APP_API}/sendNoti`, values, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingAdd(false)
                return toast.error(err?.response?.data?.message)
            } else {
                setLoadingAdd(false)
                return toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getNotification()
            setLoadingAdd(false)
            setAddMode(false)
            formik2.resetForm()
        })
    }

    //handel delete 
    async function clearNotification(_id) {
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.delete(`${process.env.REACT_APP_API}/clearNoti`, { headers }).catch((err) => {
            if (err?.response?.status == 401) {
                console.log(err);
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
            } else {
                console.log(err);
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            toast.success(res?.data?.message)
            getNotification()
        })
    }

    useEffect(() => {
        getNotification()
    }, [])


    return <>
        {AddMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik2.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>send Notification</h3>

                        <label for="title" class="form-label mainFont">title</label>
                        <input className='form-control' type="text" name='title' id='title' value={formik2.values.title} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.title && formik2.touched.title ? <div className='form-text text-danger'>{formik2.errors.title}</div> : null}

                        <label for="body" class="form-label mainFont">body</label>
                        <input className='form-control' type="text" name='body' id='body' value={formik2.values.body} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.body && formik2.touched.body ? <div className='form-text text-danger'>{formik2.errors.body}</div> : null}


                        <label for="screen" class="form-label mainFont mt-1">screen</label>

                        <Select
                            id="screen"
                            name="screen"
                            options={typeOptions}
                            value={typeOptions.find(option => option.value === formik2.values.screen)}
                            onChange={(selectedOption) => {
                                formik2.setFieldValue('screen', selectedOption.value);
                            }}
                            onBlur={formik2.handleBlur}
                        />

                        <div className='row my-2 g-3'>
                            {LoadingAdd ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik2.isValid && formik2.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Add</button>
                            }
                            <button onClick={() => {
                                formik2.resetForm()
                                setAddMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}



        <div className='container pt-5 bg-white my-5 shadow-lg'>
            <h2 className='text-center mainFont h1'>Notification</h2>

            <div className='p-5 '>
                <button onClick={() => {
                    setAddMode(true)
                }} className='btn mainBtn rounded-pill my-2 mx-auto col-12  '>send Notification</button>
                <button onClick={() => {
                    clearNotification()
                }} className='btn btn-outline-danger rounded-pill my-2 mx-auto col-12  '>Clear Notifications</button>
                {LoadingNotification ? <div className='col-12 text-center my-5 py-5'>
                    <i className='fa fa-spin fa-spinner fa-3x text-success'></i>
                </div> : <>
                    {Notification?.length != 0 ? <div className='table-responsive'>

                        <table class="table table-striped  table-hover mx-auto text-center my-5 ">
                            <thead >
                                <tr >
                                    <th scope="col" className='mainFont' >#</th>
                                    <th scope="col" className='mainFont'>title</th>
                                    <th scope="col" className='mainFont'>body</th>
                                    <th scope="col" className='mainFont'>screen</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Notification?.map((noti, index) => {
                                    return <tr className='align-baseline'>
                                        <th scope="row" className='mainFont'>{index + 1}</th>
                                        <td className='mainFont'>{noti.title}</td>
                                        <td className='mainFont'>{noti.body}</td>
                                        <td className='mainFont'>{noti.screen}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table></div> : <div className='col-12 text-center my-5 py-5'>
                        <h3 className='mainFont'>Don't have Notification</h3>
                    </div>}


                </>
                }
            </div>
        </div>
    </>
}
