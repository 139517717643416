import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import Layout from './Component/Layout/Layout.jsx';
import ProtactecdRoute from './Component/ProtactecdRoute/ProtactecdRoute.jsx';
import Login from './Component/Login/Login.jsx';
import Chaneels from './Component/Chaneels/Chaneels.jsx';
import Categorys from './Component/Categorys/Categorys.jsx';
import Matches from './Component/Matches/Matches.jsx';
import Movie from './Component/Movie/Movie.jsx';
import Series from './Component/Series/Series.jsx';
import Episodes from './Component/Episods/Episodes.jsx';
import Admins from './Component/Admins/Admins.jsx';
import Profile from './Component/Profile/Profile.jsx';
import Notification from './Component/Notification/Notification.jsx';
import StandaloneDownload from './Component/StandaloneDownload/StandaloneDownload.jsx';
import AppData from './Component/AppData/AppData.jsx';


function App() {
  const [userData, setUserData] = useState(null);

  const saveData = () => {
    setUserData(localStorage.getItem('token'));
  };

  useEffect(() => {

  }, []);

  let routes = createBrowserRouter([
    {
      path: '', element: <Layout userData={userData} setUserData={setUserData} />,
      children: [
        { index: true, element: <ProtactecdRoute><Admins setUserData={setUserData} /></ProtactecdRoute> },
        { path: 'login', element: <Login saveData={saveData} /> },
        { path: 'chaneels', element: <ProtactecdRoute><Chaneels setUserData={setUserData} /></ProtactecdRoute> },
        { path: 'categorys', element: <ProtactecdRoute><Categorys setUserData={setUserData} /> </ProtactecdRoute> },
        { path: 'matches', element: <ProtactecdRoute><Matches setUserData={setUserData} /> </ProtactecdRoute> },
        { path: 'movie', element: <ProtactecdRoute><Movie setUserData={setUserData} /></ProtactecdRoute> },
        { path: 'series', element: <ProtactecdRoute> <Series setUserData={setUserData} /></ProtactecdRoute> },
        { path: 'episodes/:_id', element: <ProtactecdRoute> <Episodes setUserData={setUserData} /></ProtactecdRoute> },
        { path: 'profile', element: <ProtactecdRoute><Profile setUserData={setUserData} /> </ProtactecdRoute> },
        { path: 'notification', element: <ProtactecdRoute><Notification setUserData={setUserData} /> </ProtactecdRoute> },
        { path: 'appData', element: <ProtactecdRoute><AppData setUserData={setUserData} /> </ProtactecdRoute> }


      ]
    }
  ])

  if (window.location.pathname === '/download-apk') {
    return <StandaloneDownload />;
  }



  return <>
    <Toaster toastOptions={{
      duration: 3000
    }} />

    <RouterProvider router={routes}>

    </RouterProvider>
  </>
}

export default App;
