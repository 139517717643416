import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom';

export default function Matches({ setUserData }) {
    const [LoadingMatches, setLoadingMatches] = useState(false)
    const [Matches, setMatches] = useState([])

    const [UpdateMode, setUpdateMode] = useState(false)
    const [LoadingUpdate, setLoadingUpdate] = useState(false)

    const [ServerMode, setServerMode] = useState(false)
    const [LoadingUpdateServer, setLoadingUpdateServer] = useState(false)

    const [DetailsMode, setDetailsMode] = useState(false)
    const [MatcheData, setMatcheData] = useState({})

    const [AddMode, setAddMode] = useState(false)
    const [LoadingAdd, setLoadingAdd] = useState(false)


    const [UpdateImageMode, setUpdateImageMode] = useState(false)
    const [LoadingUpdateImage, setLoadingUpdateImage] = useState(false)

    //handel gets
    async function getMatches() {
        setLoadingMatches(true)

        await axios(`${process.env.REACT_APP_API}/match/getMatches`).catch((err) => {
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
                setLoadingMatches(false)
            } else {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
                setLoadingMatches(false)
            }
        }).then((res) => {
            console.log(res?.data?.data);
            setMatches(res?.data?.data)
            setLoadingMatches(false)

        })
    }

    //handel add 
    let AddvalidationSchema = Yup.object({
        championship: Yup.string().required('championship is required'),
        round: Yup.string().required('round is required'),
        TeamAName: Yup.string().required('TeamAName is required'),
        TeamBName: Yup.string().required('TeamBName is required'),
        date: Yup.string().required('date is required'),
        time: Yup.string().required('time is required'),
        streamUrlsArray: Yup.array().required('streamUrl is required'),
        image1: Yup.mixed().required('TeamA Image is required'),
        image2: Yup.mixed().required('TeamB Image is required')


    })

    let formik2 = useFormik({
        initialValues: {
            championship: "",
            round: "",
            TeamAName: "",
            TeamBName: "",
            date: "",
            time: "",
            streamUrl: "", // For the input field
            streamUrlsArray: [], // For storing multiple stream URLs
            image1: "",
            image2: ""
        },
        onSubmit: handleAdd,
        validationSchema: AddvalidationSchema
    })

    async function handleAdd(values) {
        console.log(values);

        setLoadingAdd(true)
        let formData = new FormData()
        formData.append('championship', values.championship)
        formData.append('round', values.round)
        formData.append('TeamAName', values.TeamAName)
        formData.append('TeamBName', values.TeamBName)
        formData.append('date', values.date)
        formData.append('time', values.time)
        values.streamUrlsArray.forEach(element => {
            formData.append('streamUrl', element)
        });

        formData.append('image1', values.image1)
        formData.append('image2', values.image2)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.post(`${process.env.REACT_APP_API}/match/addMatch`, formData, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingAdd(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingAdd(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getMatches()
            setLoadingAdd(false)
            formik2.resetForm()
            setAddMode(false)
        })
    }
    //handel update 
    let validationSchema = Yup.object({
        championship: Yup.string().required('championship is required'),
        round: Yup.string().required('round is required'),
        TeamAName: Yup.string().required('TeamAName is required'),
        TeamBName: Yup.string().required('TeamBName is required'),
        date: Yup.string().required('date is required'),
        time: Yup.string().required('time is required')
    })
    let formik = useFormik({
        initialValues: {
            _id: "",
            championship: "",
            round: "",
            TeamAName: "",
            TeamBName: "",
            date: "",
            time: ""
        },
        onSubmit: handleUpdate,
        validationSchema
    })

    async function handleUpdate(values) {
        console.log(values);
        setLoadingUpdate(true)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.patch(`${process.env.REACT_APP_API}/match/updateMatch/${values._id}`, {
            championship: values.championship,
            round: values.round,
            TeamAName: values.TeamAName,
            TeamBName: values.TeamBName,
            time: values.time,
            date: values.date
        }, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdate(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdate(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getMatches()
            setLoadingUpdate(false)
            setUpdateMode(false)


        })
    }

    async function handelUpdatedData(Matche) {
        formik.setValues({
            _id: Matche._id,
            championship: Matche.championship,
            round: Matche.round,
            TeamAName: Matche.TeamA.name,
            TeamBName: Matche.TeamB.name,
            time: Matche.time,
            date: Matche.date
        })
        setUpdateMode(true)

    }

    //handel update Servers 
    let validationSchemaServers = Yup.object({
        streamUrlsArray: Yup.array().required('streamUrl is required')
    })
    let formik4 = useFormik({
        initialValues: {
            _id: "",
            streamUrlsArray: [],
            streamUrl: ""
        },
        onSubmit: handleUpdateServers,
        validationSchema: validationSchemaServers
    })

    async function handleUpdateServers(values) {
        console.log(values);
        setLoadingUpdateServer(true)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.patch(`${process.env.REACT_APP_API}/match/updateMatchServer/${values._id}`, {
            streamUrl: values.streamUrlsArray
        }, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdateServer(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdateServer(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getMatches()
            setLoadingUpdateServer(false)
            setServerMode(false)


        })
    }

    async function handelUpdatedServersData(Matche) {
        formik4.setValues({
            _id: Matche._id,
            streamUrlsArray: Matche.streamUrl
        })
        setServerMode(true)
    }

    async function handelDeletedServersData(url) {
        const newServers = formik4.values.streamUrlsArray.filter((element) => element != url)
        formik4.setFieldValue(
            'streamUrlsArray', newServers
        )
    }


    //handel update image
    let UpdateImageValidationSchema = Yup.object({
        image1: Yup.mixed().required('TeamA Image is required'),
        image2: Yup.mixed().required('TeamB Image is required')

    })
    let formik3 = useFormik({
        initialValues: {
            _id: "",
            image1: "",
            image2: ""
        },
        onSubmit: handleUpdateImage,
        validationSchema: UpdateImageValidationSchema
    })
    async function handleUpdateImage(values) {
        setLoadingUpdateImage(true)

        let formData = new FormData()
        formData.append('image1', values.image1)
        formData.append('image2', values.image2)


        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios.patch(`${process.env.REACT_APP_API}/match/updateMatchImages/${values._id}`, formData, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdateImage(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdateImage(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getMatches()
            setLoadingUpdateImage(false)
            setUpdateImageMode(false)
            formik3.resetForm()
        })
    }
    //handel delete 
    async function deleteMatche(_id) {
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.delete(`${process.env.REACT_APP_API}/match/deleteMatch/${_id}`, { headers }).catch((err) => {
            if (err?.response?.status == 401) {
                console.log(err);
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
            } else {
                console.log(err);
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            toast.success(res?.data?.message)
            getMatches()
        })
    }

    useEffect(() => {
        getMatches()
    }, [])


    return <>
        {AddMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onKeyDown={(e) => {
                        if (e.key === 'Enter' && formik2.values.streamUrl.trim() !== '') {
                            e.preventDefault();
                            // Add the URL to the array
                            formik2.setFieldValue('streamUrlsArray', [...formik2.values.streamUrlsArray, formik2.values.streamUrl.trim()]);
                            // Clear the input field
                            formik2.setFieldValue('streamUrl', '');
                        }
                    }}
                        onSubmit={formik2.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow overflow-auto scrollable-content' style={{ maxHeight: '90vh' }}>
                        <h3 className='text-center mainFont '>Add Matche</h3>

                        <label for="championship" class="form-label mainFont">championship</label>
                        <input className='form-control' type="text" name='championship' id='championship' value={formik2.values.championship} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.championship && formik2.touched.championship ? <div className='form-text text-danger'>{formik2.errors.championship}</div> : null}


                        <label for="round" class="form-label mainFont mt-2 ">round</label>
                        <input className='form-control' type="text" name='round' id='round' value={formik2.values.round} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.round && formik2.touched.round ? <div className='form-text text-danger'>{formik2.errors.round}</div> : null}


                        <label for="TeamAName" class="form-label mainFont mt-2 ">Team A</label>
                        <input className='form-control' type="text" name='TeamAName' id='TeamAName' value={formik2.values.TeamAName} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.TeamAName && formik2.touched.TeamAName ? <div className='form-text text-danger'>{formik2.errors.TeamAName}</div> : null}


                        <label for="image1" class="form-label mainFont mt-2">Team A logo</label>
                        <input
                            onChange={(event) => formik2.setFieldValue('image1', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='image1'
                            id='image1'
                            onBlur={formik2.handleBlur} />
                        {formik2.errors.image1 && formik2.touched.image1 ? <div className='form-text text-danger'>{formik2.errors.image1}</div> : null}



                        <label for="TeamBName" class="form-label mainFont mt-2 ">Team B</label>
                        <input className='form-control' type="text" name='TeamBName' id='TeamBName' value={formik2.values.TeamBName} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.TeamBName && formik2.touched.TeamBName ? <div className='form-text text-danger'>{formik2.errors.TeamBName}</div> : null}

                        <label for="image2" class="form-label mainFont mt-2">Team B logo</label>
                        <input
                            onChange={(event) => formik2.setFieldValue('image2', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='image2'
                            id='image2'
                            onBlur={formik2.handleBlur} />
                        {formik2.errors.image2 && formik2.touched.image2 ? <div className='form-text text-danger'>{formik2.errors.image2}</div> : null}

                        <label for="date" class="form-label mainFont mt-2 ">date</label>
                        <input className='form-control' type="date" name='date' id='date' value={formik2.values.date} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.date && formik2.touched.date ? <div className='form-text text-danger'>{formik2.errors.date}</div> : null}

                        <label for="time" class="form-label mainFont mt-2 ">time</label>
                        <input className='form-control' type="time" name='time' id='time' value={formik2.values.time} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.time && formik2.touched.time ? <div className='form-text text-danger'>{formik2.errors.time}</div> : null}

                        <label for="streamUrl" class="form-label mainFont mt-2 ">streamUrl</label>
                        <input
                            className='form-control'
                            type="text"
                            name='streamUrl'
                            id='streamUrl'
                            value={formik2.values.streamUrl}
                            onChange={formik2.handleChange}
                            onBlur={formik2.handleBlur}
                        />
                        <div className='form-text text-danger'>press enter for add a new server</div>
                        {formik2.errors.streamUrl && formik2.touched.streamUrl ? <div className='form-text text-danger'>{formik2.errors.streamUrl}</div> : null}
                        <ol class="list-group list-group-numbered mt-3">
                            {formik2.values.streamUrlsArray.map((url, index) => (
                                <li class="list-group-item" key={index}>{url}</li>
                            ))}
                        </ol>

                        <div className='row my-2 g-3'>
                            {LoadingAdd ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik2.isValid && formik2.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Add</button>
                            }
                            <button onClick={() => {
                                formik2.resetForm()
                                setAddMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {ServerMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onKeyDown={(e) => {
                        if (e.key === 'Enter' && formik4.values.streamUrl.trim() !== '') {
                            e.preventDefault();
                            // Add the URL to the array
                            formik4.setFieldValue('streamUrlsArray', [...formik4.values.streamUrlsArray, formik4.values.streamUrl.trim()]);
                            // Clear the input field
                            formik4.setFieldValue('streamUrl', '');
                        }
                    }}
                        onSubmit={formik4.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow overflow-auto scrollable-content' style={{ maxHeight: '90vh' }}>
                        <h3 className='text-center mainFont '>Servers</h3>

                        <label for="streamUrl" class="form-label mainFont mt-2 ">streamUrl</label>
                        <input
                            className='form-control'
                            type="text"
                            name='streamUrl'
                            id='streamUrl'
                            value={formik4.values.streamUrl}
                            onChange={formik4.handleChange}
                            onBlur={formik4.handleBlur}
                        />

                        <div className='form-text text-danger'>press enter for add a new server</div>
                        {formik4.errors.streamUrl && formik4.touched.streamUrl ? <div className='form-text text-danger'>{formik4.errors.streamUrl}</div> : null}
                        <ol class="list-group list-group-numbered mt-3">
                            {formik4.values.streamUrlsArray.map((url, index) => (
                                <li key={index} class="list-group-item d-flex justify-content-between align-items-center">
                                    {url}
                                    <span class="badge text-bg-danger rounded-pill mainClick" onClick={() => { handelDeletedServersData(url) }}><i class="fa-regular fa-trash-can "></i></span>
                                </li>
                            ))}
                        </ol>

                        <div className='row my-2 g-3'>
                            {LoadingUpdateServer ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik4.isValid && formik4.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Add</button>
                            }
                            <button onClick={() => {
                                formik4.resetForm()
                                setServerMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateImageMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik3.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow ' >
                        <h3 className='text-center mainFont '>Update Matche Image</h3>

                        <label for="image1" class="form-label mainFont mt-2">Team A logo</label>
                        <input
                            onChange={(event) => formik3.setFieldValue('image1', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='image1'
                            id='image1'
                            onBlur={formik3.handleBlur} />
                        {formik3.errors.image1 && formik3.touched.image1 ? <div className='form-text text-danger'>{formik3.errors.image1}</div> : null}

                        <label for="image2" class="form-label mainFont mt-2">Team B logo</label>
                        <input
                            onChange={(event) => formik3.setFieldValue('image2', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='image2'
                            id='image2'
                            onBlur={formik3.handleBlur} />
                        {formik3.errors.image2 && formik3.touched.image2 ? <div className='form-text text-danger'>{formik3.errors.image2}</div> : null}



                        <div className='row my-2 g-3'>
                            {LoadingUpdateImage ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik3.isValid && formik3.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Update</button>
                            }
                            <button onClick={() => {
                                formik3.resetForm()
                                setUpdateImageMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow overflow-auto scrollable-content' style={{ maxHeight: '90vh' }}>
                        <h3 className='text-center mainFont '>Update Matche</h3>


                        <label for="championship" class="form-label mainFont">championship</label>
                        <input className='form-control' type="text" name='championship' id='championship' value={formik.values.championship} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.championship && formik.touched.championship ? <div className='form-text text-danger'>{formik.errors.championship}</div> : null}


                        <label for="round" class="form-label mainFont mt-2 ">round</label>
                        <input className='form-control' type="text" name='round' id='round' value={formik.values.round} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.round && formik.touched.round ? <div className='form-text text-danger'>{formik.errors.round}</div> : null}


                        <label for="TeamAName" class="form-label mainFont mt-2 ">Team A</label>
                        <input className='form-control' type="text" name='TeamAName' id='TeamAName' value={formik.values.TeamAName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.TeamAName && formik.touched.TeamAName ? <div className='form-text text-danger'>{formik.errors.TeamAName}</div> : null}





                        <label for="TeamBName" class="form-label mainFont mt-2 ">Team B</label>
                        <input className='form-control' type="text" name='TeamBName' id='TeamBName' value={formik.values.TeamBName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.TeamBName && formik.touched.TeamBName ? <div className='form-text text-danger'>{formik.errors.TeamBName}</div> : null}


                        <label for="date" class="form-label mainFont mt-2 ">date</label>
                        <input className='form-control' type="date" name='date' id='date' value={formik.values.date} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.date && formik.touched.date ? <div className='form-text text-danger'>{formik.errors.date}</div> : null}

                        <label for="time" class="form-label mainFont mt-2 ">time</label>
                        <input className='form-control' type="time" name='time' id='time' value={formik.values.time} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.time && formik.touched.time ? <div className='form-text text-danger'>{formik.errors.time}</div> : null}



                        <div className='row my-2 g-3'>
                            {LoadingUpdate ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>update</button>
                            }
                            <button onClick={() => { setUpdateMode(false) }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}



        {DetailsMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-lg-6 col-md-8 col-10 formRes'>
                    <>
                        <div className="w-100 my-5 bg-light  p-5 rounded-3 shadow text-center overflow-auto scrollable-content " style={{ maxHeight: '90vh' }}>
                            <h3 className='text-center mainFont '>Matche Details</h3>

                            <div className='row justify-content-between  align-items-center' >
                                <div className='col-md-4'>
                                    <img src={MatcheData.TeamA.OpenImage} className="bg-body-secondary p-1 rounded-circle img-fluid shadow-lg fixed-img " alt="..." />
                                    <h5 className='py-4'>{MatcheData.TeamA.name}</h5>
                                </div>
                                <div className='col-md-4'>
                                    <p>{MatcheData.championship}</p>
                                    <p>{MatcheData.round}</p>
                                    <p>{MatcheData.time}</p>
                                    <p>{MatcheData.date}</p>
                                </div>
                                <div className='col-md-4'>
                                    <img src={MatcheData.TeamB.OpenImage} className="bg-body-secondary p-1 rounded-circle img-fluid shadow-lg fixed-img  " alt="..." />
                                    <h5 className='py-4'>{MatcheData.TeamB.name}</h5>
                                </div>

                            </div>

                            <ol class="list-group list-group-numbered my-3">
                                {MatcheData.streamUrl.map((url, index) => (
                                    <li class="list-group-item" key={index}>{url}</li>
                                ))}
                            </ol>
                            <button onClick={() => { setDetailsMode(false) }} type='reset' className='btn mx-auto mainBtn col-12 rounded-pill'>Close</button>

                        </div>
                    </>
                </div>
            </div>
            : null}


        <div className='container pt-5 bg-white my-5 shadow-lg'>
            <h2 className='text-center mainFont h1'>Matches</h2>

            <div className='p-5 '>
                <button onClick={() => {
                    setAddMode(true)
                }} className='btn mainBtn rounded-pill my-2 mx-auto col-12  '>Add Matche</button>
                {LoadingMatches ? <div className='col-12 text-center my-5 py-5'>
                    <i className='fa fa-spin fa-spinner fa-3x text-success'></i>
                </div> : <>
                    {Matches?.length != 0 ? <div className='table-responsive py-5'>
                        <div className='py-5'>
                            <table class="table table-striped   table-hover mx-auto text-center my-5 ">
                                <thead >
                                    <tr >
                                        <th scope="col" className='mainFont' >#</th>
                                        <th scope="col" className='mainFont'>championship</th>
                                        <th scope="col" className='mainFont'>round</th>
                                        <th scope="col" className='mainFont'>TeamA</th>
                                        <th scope="col" className='mainFont'>TeamB</th>
                                        <th scope="col" className='mainFont'>time</th>


                                        <th scope="col" className='mainFont'>Acthions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {Matches?.map((Matche, index) => {
                                        return <tr className='align-baseline'>
                                            <th scope="row" className='mainFont'>{index + 1}</th>
                                            <td className='mainFont'>{Matche.championship}</td>
                                            <td className='mainFont'>{Matche.round}</td>
                                            <td className='mainFont'>{Matche.TeamA.name}</td>
                                            <td className='mainFont'>{Matche.TeamB.name}</td>
                                            <td className='mainFont'>{Matche.time}</td>

                                            <td>
                                                <div class="dropdown">
                                                    <button class="btn mainIcon  " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="fa-solid fa-list fa-0 mainFont"></i>
                                                    </button>
                                                    <ul class="dropdown-menu ">
                                                        <li onClick={() => { deleteMatche(Matche._id) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-trash-can me-2"></i>delete</li>
                                                        <li onClick={() => { handelUpdatedData(Matche) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update</li>
                                                        <li onClick={() => { handelUpdatedServersData(Matche) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update Servers</li>

                                                        <li onClick={() => {
                                                            formik3.setFieldValue("_id", Matche._id)
                                                            setUpdateImageMode(true)
                                                        }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update Image</li>

                                                        <li onClick={() => {
                                                            setMatcheData(Matche)
                                                            console.log(Matche);
                                                            setDetailsMode(true)
                                                        }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-eye me-2"></i>Details</li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div> : <div className='col-12 text-center my-5 py-5'>
                        <h3 className='mainFont'>Don't have Matches</h3>
                    </div>}


                </>
                }
            </div>

        </div>

    </>
}
