import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom';

export default function Episodes({ setUserData }) {
    const { _id } = useParams();

    const [LoadingSeries, setLoadingSeries] = useState(false)
    const [Series, setSeries] = useState([])

    const [UpdateMode, setUpdateMode] = useState(false)
    const [LoadingUpdate, setLoadingUpdate] = useState(false)

    const [AddMode, setAddMode] = useState(false)
    const [LoadingAdd, setLoadingAdd] = useState(false)

    async function getOneSeries() {
        setLoadingSeries(true)
        console.log(_id);
        await axios(`${process.env.REACT_APP_API}/series/getOneSeries/${_id}`).catch((err) => {
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
                setLoadingSeries(false)
            } else {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
                setLoadingSeries(false)
            }
        }).then((res) => {
            console.log(res);
            setSeries(res?.data?.data)
            setLoadingSeries(false)

        })
    }

    //handel add 
    let AddvalidationSchema = Yup.object({
        title: Yup.number().required('title is required'),
        videoUrl: Yup.string().required('videoUrl is required')
    })

    let formik2 = useFormik({
        initialValues: {
            title: "",
            videoUrl: ""
        },
        onSubmit: handleAdd,
        validationSchema: AddvalidationSchema
    })

    async function handleAdd(values) {
        try {
            console.log(values);
            setLoadingAdd(true);

            let token = localStorage.getItem('token');
            let headers = {
                Authorization: `Bearer ${token}`
            };

            const res = await axios.post(
                `${process.env.REACT_APP_API}/series/addEpisode/${_id}`,
                values,
                { headers }
            );

            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message);
            getOneSeries();
            setAddMode(false);
            formik2.resetForm();
        } catch (err) {
            console.log(err);

            if (err?.response?.status === 401) {
                localStorage.clear();
                setUserData(null);
            }

            const errorMessage = err?.response?.data?.message || 'An unexpected error occurred';
            toast.error(errorMessage);
        } finally {
            setLoadingAdd(false);
        }
    }


    //handel update 
    let validationSchema = Yup.object({
        title: Yup.number().required('title is required'),
        videoUrl: Yup.string().required('videoUrl is required')
    })

    let formik = useFormik({
        initialValues: {
            _id: "",
            title: "",
            Series: "",
            videoUrl: ""
        },
        onSubmit: handleUpdate,
        validationSchema
    })

    async function handleUpdate(values) {
        try {
            console.log(values);
            setLoadingUpdate(true);
    
            let token = localStorage.getItem('token');
            let headers = {
                Authorization: `Bearer ${token}`
            };
    
            const response = await axios.patch(
                `${process.env.REACT_APP_API}/series/updateEpisode/${values._id}`,
                {
                    title: values.title,
                    videoUrl: values.videoUrl,
                    Series: values.Series
                },
                { headers }
            );
    
            console.log(response);
            console.log(response?.data?.data);
            toast.success(response?.data?.message);
    
            getOneSeries();
            setUpdateMode(false);
        } catch (err) {
            console.log(err);
    
            if (err?.response?.status === 401) {
                localStorage.clear();
                setUserData(null);
            }
    
            toast.error(err?.response?.data?.message);
        } finally {
            setLoadingUpdate(false);
        }
    }
    

    async function handelUpdatedData(Episodes, _id) {
        console.log(_id);

        formik.setValues({
            _id: Episodes._id,
            Series: _id,
            title: Episodes.title,
            videoUrl: Episodes.videoUrl
        })
        setUpdateMode(true)

    }

    // handel delete 
    async function deleteEpisodes(_id) {
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.delete(`${process.env.REACT_APP_API}/series/deleteEpisode/${_id}`, { headers }).catch((err) => {
            if (err?.response?.status == 401) {
                console.log(err);
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
            } else {
                console.log(err);
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            toast.success(res?.data?.message)
            getOneSeries()
        })
    }

    useEffect(() => {
        getOneSeries()
    }, [])

    return <>
        {AddMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik2.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Add Episodes</h3>

                        <label for="title" class="form-label mainFont">name</label>
                        <input className='form-control' type="number" name='title' id='title' value={formik2.values.title} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.title && formik2.touched.title ? <div className='form-text text-danger'>{formik2.errors.title}</div> : null}


                        <label for="videoUrl" class="form-label mainFont mt-2 ">videoUrl</label>
                        <input className='form-control' type="text" name='videoUrl' id='videoUrl' value={formik2.values.videoUrl} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.videoUrl && formik2.touched.videoUrl ? <div className='form-text text-danger'>{formik2.errors.videoUrl}</div> : null}

                        <div className='row my-2 g-3'>
                            {LoadingAdd ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik2.isValid && formik2.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Add</button>
                            }
                            <button onClick={() => {
                                formik2.resetForm()
                                setAddMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Update Episode</h3>

                        <label for="title" class="form-label mainFont">name</label>
                        <input className='form-control' type="number" name='title' id='title' value={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.title && formik.touched.title ? <div className='form-text text-danger'>{formik.errors.title}</div> : null}

                        <label for="videoUrl" class="form-label mainFont mt-2 ">videoUrl</label>
                        <input className='form-control' type="text" name='videoUrl' id='videoUrl' value={formik.values.videoUrl} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.videoUrl && formik.touched.videoUrl ? <div className='form-text text-danger'>{formik.errors.videoUrl}</div> : null}

                        <div className='row my-2 g-3'>
                            {LoadingUpdate ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>update</button>
                            }
                            <button onClick={() => { setUpdateMode(false) }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        <div className='container pt-5 bg-white my-5 shadow-lg'>
            <h2 className='text-center mainFont h1'>Episodes</h2>
            <div className='p-5 '>
                <div className='row justify-content-center align-items-center '>
                    <div className="col-md-4 ">
                        <div className='p-5 '>
                            <img src={Series?.image?.OpenImage} className='img-fluid rounded-3' alt="" srcset="" />
                        </div>
                    </div>
                    <div className="col-md-5 ">
                        <div className='p-5 '>
                            <h5><span className='fw-bolder'>Title : </span>{Series?.title}</h5>
                            <h5><span className='fw-bolder'>Description : </span>{Series?.description}</h5>
                            <h5><span className='fw-bolder'>Release Date : </span>{Series?.releaseDate}</h5>
                            <h5><span className='fw-bolder'>Category : </span>{Series?.category?.title}</h5>
                            <h5><span className='fw-bolder'>Rating : </span>{Series.rating}</h5>
                            <h5><span className='fw-bolder'>Language : </span>{Series.lang}</h5>
                        </div>
                    </div>
                </div>
                <button onClick={() => {
                    setAddMode(true)
                }} className='btn mainBtn rounded-pill my-2 mx-auto col-12  '>Add Episode</button>

                {LoadingSeries ? <div className='col-12 text-center my-5 py-5'>
                    <i className='fa fa-spin fa-spinner fa-3x text-success'></i>
                </div> : <>
                    {Series?.episodes?.length != 0 ? <div className='table-responsive py-5'>
                        <div className="py-5">
                            <table class="table table-striped  table-hover mx-auto text-center my-5 ">
                                <thead >
                                    <tr >
                                        <th scope="col" className='mainFont' >#</th>
                                        <th scope="col" className='mainFont'>title</th>
                                        <th scope="col" className='mainFont'>video url</th>
                                        <th scope="col" className='mainFont'>Acthions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Series?.episodes?.map((episode, index) => {
                                        return <tr className='align-baseline'>
                                            <th scope="row" className='mainFont'>{index + 1}</th>
                                            <td className='mainFont'>{episode.title}</td>
                                            <td className='mainFont'>{episode.videoUrl}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button class="btn mainIcon  " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="fa-solid fa-list fa-0 mainFont"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li onClick={() => { deleteEpisodes(episode._id) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-trash-can me-2"></i>delete</li>
                                                        <li onClick={() => { handelUpdatedData(episode, Series._id) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update</li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div> : <div className='col-12 text-center my-5 py-5'>
                        <h3 className='mainFont'>Don't have Episodes</h3>
                    </div>}
                </>}
            </div>

        </div>

    </>
}
