import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom';
import Select from 'react-select';

export default function Chaneels({ setUserData }) {
    const [LoadingChannels, setLoadingChannels] = useState(false)
    const [Channels, setChannels] = useState([])

    const [LoadingCategories, setLoadingCategories] = useState(false)

    const [Categories, setCategories] = useState([])

    const [UpdateMode, setUpdateMode] = useState(false)
    const [LoadingUpdate, setLoadingUpdate] = useState(false)

    const [DetailsMode, setDetailsMode] = useState(false)
    const [ChannelData, setChannelData] = useState({})

    const [AddMode, setAddMode] = useState(false)
    const [LoadingAdd, setLoadingAdd] = useState(false)


    const [UpdateImageMode, setUpdateImageMode] = useState(false)
    const [LoadingUpdateImage, setLoadingUpdateImage] = useState(false)

    const [langOptions, setlangOptions] = useState([{ value: 'ar', label: "Arabic" }, { value: 'en', label: "English" }])
    const [categoryOptions, setcategoryOptions] = useState([])


    const [TrendingMode, setTrendingMode] = useState(false)
    const [LoadingTrending, setLoadingTrending] = useState(false)
    const [ChannelOptions, setChannelOptions] = useState([])


    //handel gets
    async function getChannels() {
        setLoadingChannels(true)


        await axios(`${process.env.REACT_APP_API}/chaneel/getChannels`).catch((err) => {
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
                setLoadingChannels(false)
            } else {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
                setLoadingChannels(false)
            }
        }).then((res) => {
            setChannels(res?.data?.data)
            setChannelOptions(res?.data?.data?.map((cate) => ({
                value: cate._id,
                label: cate.title,
            })))
            setLoadingChannels(false)

        })
    }
    async function getChannelCategory() {
        setLoadingCategories(true)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios(`${process.env.REACT_APP_API}/category/getcategories/channel`).catch((err) => {
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
                setLoadingCategories(false)
            } else {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
                setLoadingCategories(false)
            }
        }).then((res) => {
            console.log(res);
            setcategoryOptions(res?.data?.data?.map((cate) => ({
                value: cate._id,
                label: cate.title,
            })))
            setLoadingCategories(false)

        })
    }

    //handel add 
    let AddvalidationSchema = Yup.object({
        title: Yup.string().required('title is required'),
        category: Yup.string().required('category is required'),
        lang: Yup.string().required('lang is required'),
        streamUrl: Yup.string().required('streamUrl is required'),
        ChannelImage: Yup.mixed().required('Channel Image is required')
    })

    let formik2 = useFormik({
        initialValues: {
            title: "",
            category: "",
            lang: "",
            streamUrl: "",
            ChannelImage: ""
        },
        onSubmit: handleAdd,
        validationSchema: AddvalidationSchema
    })

    async function handleAdd(values) {

        setLoadingAdd(true)

        let formData = new FormData()
        formData.append('title', values.title)
        formData.append('streamUrl', values.streamUrl)
        formData.append('lang', values.lang)
        formData.append('category', values.category)
        formData.append('image', values.ChannelImage)

        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios.post(`${process.env.REACT_APP_API}/chaneel/addChannel`, formData, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingAdd(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingAdd(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getChannels()
            setLoadingAdd(false)
            setAddMode(false)
            formik2.resetForm()
        })
    }
    //handel update 

    let validationSchema = Yup.object({
        title: Yup.string().required('title is required'),
        category: Yup.string().required('category is required'),
        lang: Yup.string().required('lang is required'),
        streamUrl: Yup.string().required('streamUrl is required')
    })

    let formik = useFormik({
        initialValues: {
            _id: "",
            title: "",
            category: "",
            lang: "",
            streamUrl: ""
        },
        onSubmit: handleUpdate,
        validationSchema
    })

    async function handleUpdate(values) {
        console.log(values);
        setLoadingUpdate(true)
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.patch(`${process.env.REACT_APP_API}/chaneel/updateChannelData/${values._id}`, {
            title: values.title,
            category: values.category,
            lang: values.lang,
            streamUrl: values.streamUrl
        }, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdate(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdate(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getChannels()
            setLoadingUpdate(false)
            setUpdateMode(false)


        })
    }

    async function handelUpdatedData(channel) {
        getChannelCategory()
        formik.setValues({
            _id: channel._id,
            title: channel.title,
            category: channel.category._id,
            lang: channel.lang,
            streamUrl: channel.streamUrl
        })
        setUpdateMode(true)

    }

    //handel update image
    let UpdateImageValidationSchema = Yup.object({
        ChannelImage: Yup.mixed().required('Channel Image is required')
    })
    let formik3 = useFormik({
        initialValues: {
            _id: "",
            ChannelImage: ""
        },
        onSubmit: handleUpdateImage,
        validationSchema: UpdateImageValidationSchema
    })
    async function handleUpdateImage(values) {
        setLoadingUpdateImage(true)

        let formData = new FormData()
        formData.append('image', values.ChannelImage)

        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios.patch(`${process.env.REACT_APP_API}/chaneel/updateChannelImage/${values._id}`, formData, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingUpdateImage(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingUpdateImage(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getChannels()
            setLoadingUpdateImage(false)
            setUpdateImageMode(false)
            formik3.resetForm()
        })
    }

    //handleTrending
    let TrendinValidationSchema = Yup.object({
        Channels: Yup.array().length(5, 'Channels length must be 5').required('Channels is required')
    })
    let formik4 = useFormik({
        initialValues: {
            Channels: []
        },
        onSubmit: handleTrending,
        validationSchema: TrendinValidationSchema
    })
    async function handleTrending(values) {
        console.log(values);

        setLoadingTrending(true)

        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }

        await axios.patch(`${process.env.REACT_APP_API}/chaneel/addChannelToTrend`, values, { headers }).catch((err) => {
            console.log(err);
            if (err?.response?.status == 401) {
                localStorage.clear()
                setUserData(null)
                setLoadingTrending(false)
                toast.error(err?.response?.data?.message)
            } else {
                setLoadingTrending(false)
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            console.log(res?.data?.data);
            toast.success(res?.data?.message)
            getChannels()
            setLoadingTrending(false)
            setTrendingMode(false)
            formik4.resetForm()
        })
    }

    //handel delete 
    async function deleteChannel(_id) {
        let token = localStorage.getItem('token')
        let headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.delete(`${process.env.REACT_APP_API}/chaneel/deleteChannel/${_id}`, { headers }).catch((err) => {
            if (err?.response?.status == 401) {
                console.log(err);
                localStorage.clear()
                setUserData(null)
                toast.error(err?.response?.data?.message)
            } else {
                console.log(err);
                toast.error(err?.response?.data?.message)
            }
        }).then((res) => {
            console.log(res);
            toast.success(res?.data?.message)
            getChannels()
        })
    }

    useEffect(() => {
        getChannels()
    }, [])


    return <>
        {AddMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik2.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Add channel</h3>

                        <label for="title" class="form-label mainFont">name</label>
                        <input className='form-control' type="text" name='title' id='title' value={formik2.values.title} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.title && formik2.touched.title ? <div className='form-text text-danger'>{formik2.errors.title}</div> : null}


                        <label for="streamUrl" class="form-label mainFont mt-2 ">stream Url</label>
                        <input className='form-control' type="text" name='streamUrl' id='streamUrl' value={formik2.values.streamUrl} onChange={formik2.handleChange} onBlur={formik2.handleBlur} />
                        {formik2.errors.streamUrl && formik2.touched.streamUrl ? <div className='form-text text-danger'>{formik2.errors.streamUrl}</div> : null}


                        <label for="lang" class="form-label mainFont mt-2">Language</label>

                        <Select
                            id="lang"
                            name="lang"
                            options={langOptions}
                            value={langOptions.find(option => option.value === formik2.values.lang)}
                            onChange={(selectedOption) => {
                                formik2.setFieldValue('lang', selectedOption.value);
                            }}
                            onBlur={formik2.handleBlur}
                        />





                        <label for="category" class="form-label mainFont mt-2">Category</label>

                        <Select
                            id="category"
                            name="category"
                            options={categoryOptions}
                            value={categoryOptions.find(option => option.value === formik2.values.category)}
                            onChange={(selectedOption) => {
                                formik2.setFieldValue('category', selectedOption.value);
                            }}
                            onBlur={formik2.handleBlur}
                        />


                        <label for="ChannelImage" class="form-label mainFont mt-2">Channel Image</label>
                        <input
                            onChange={(event) => formik2.setFieldValue('ChannelImage', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='ChannelImage'
                            id='ChannelImage'
                            onBlur={formik2.handleBlur} />
                        {formik2.errors.ChannelImage && formik2.touched.ChannelImage ? <div className='form-text text-danger'>{formik2.errors.ChannelImage}</div> : null}


                        <div className='row my-2 g-3'>
                            {LoadingAdd ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik2.isValid && formik2.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Add</button>
                            }
                            <button onClick={() => {
                                formik2.resetForm()
                                setAddMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateImageMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik3.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Update channel image</h3>

                        <label for="ChannelImage" class="form-label mainFont">Channel Image</label>
                        <input
                            onChange={(event) => formik3.setFieldValue('ChannelImage', event.currentTarget.files[0])}
                            className='form-control'
                            type="file"
                            name='ChannelImage'
                            id='ChannelImage'
                            onBlur={formik3.handleBlur} />
                        {formik3.errors.ChannelImage && formik3.touched.ChannelImage ? <div className='form-text text-danger'>{formik3.errors.ChannelImage}</div> : null}


                        <div className='row my-2 g-3'>
                            {LoadingUpdateImage ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik3.isValid && formik3.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Update</button>
                            }
                            <button onClick={() => {
                                formik3.resetForm()
                                setUpdateImageMode(false)
                            }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {UpdateMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow '>
                        <h3 className='text-center mainFont '>Update channel</h3>
                        <label for="title" class="form-label mainFont">name</label>
                        <input className='form-control' type="text" name='title' id='title' value={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.title && formik.touched.title ? <div className='form-text text-danger'>{formik.errors.title}</div> : null}


                        <label for="streamUrl" class="form-label mainFont mt-2 ">stream Url</label>
                        <input className='form-control' type="text" name='streamUrl' id='streamUrl' value={formik.values.streamUrl} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.errors.streamUrl && formik.touched.streamUrl ? <div className='form-text text-danger'>{formik.errors.streamUrl}</div> : null}

                        <label for="lang" class="form-label mainFont mt-2">Language</label>

                        <Select
                            id="lang"
                            name="lang"
                            options={langOptions}
                            value={langOptions.find(option => option.value === formik.values.lang)}
                            onChange={(selectedOption) => {
                                formik.setFieldValue('lang', selectedOption.value);
                            }}
                            onBlur={formik.handleBlur}
                        />





                        <label for="category" class="form-label mainFont mt-2">Category</label>

                        <Select
                            id="category"
                            name="category"
                            options={categoryOptions}
                            value={categoryOptions.find(option => option.value === formik.values.category)}
                            onChange={(selectedOption) => {
                                formik.setFieldValue('category', selectedOption.value);
                            }}
                            onBlur={formik.handleBlur}
                        />


                        <div className='row my-2 g-3'>
                            {LoadingUpdate ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>update</button>
                            }
                            <button onClick={() => { setUpdateMode(false) }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        {DetailsMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <>

                        <div className="w-100 my-5 bg-light  p-5 rounded-3 shadow text-center overflow-auto scrollable-content " style={{ maxHeight: '90vh' }}>
                            <h3 className='text-center mainFont '>Channel Details</h3>
                            <div className='row justify-content-center align-items-center p-4' >
                                <div className='col-md-6 py-3'>
                                    <img src={ChannelData.image.OpenImage} className="bg-body-secondary p-1 img-fluid shadow-lg fixed-img " alt="..." />
                                </div>
                                <div className='col-md-6 text-start'>
                                    <h5 className="card-text">{ChannelData.title}</h5>
                                    <p className="card-text">category : {ChannelData.category.title}</p>
                                    <p className="card-text">lang : {ChannelData.lang}</p>
                                    <p className="card-text">streamUrl : {ChannelData.streamUrl}</p>
                                </div>
                            </div>
                            <button onClick={() => { setDetailsMode(false) }} className='btn mx-auto mainBtn col-12 rounded-pill'>Close</button>
                        </div>
                    </>
                </div>
            </div>
            : null}

        {TrendingMode ?
            <div className='start-0 end-0 top-0 bottom-0   bg-body-secondary bg-opacity-50 fixed-top row justify-content-center align-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 col-10 formRes'>
                    <form onSubmit={formik4.handleSubmit} className='w-100 my-5 bg-light  p-5 rounded-3 shadow overflow-auto scrollable-content' style={{ maxHeight: '90vh' }}>
                        <h3 className='text-center mainFont '>Trending Channels</h3>



                        <label for="Channels" class="form-label mainFont mt-2">Select 5 Channels</label>

                        <Select
                            id="Channels"
                            name="Channels"
                            options={ChannelOptions}
                            isMulti
                            value={ChannelOptions.filter(option => formik4.values.Channels.includes(option.value))}
                            onChange={(selectedOptions) => {
                                const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                formik4.setFieldValue('Channels', values);
                            }}
                            onBlur={formik4.handleBlur}
                            className=''
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuShouldScrollIntoView={true}
                            styles={{
                                menuList: (provided) => ({
                                    ...provided,
                                    maxHeight: '160px', // Adjust the height as needed
                                    overflowY: 'auto',
                                    WebkitOverflowScrolling: 'touch', // Ensures smooth scrolling on iOS devices
                                    touchAction: 'auto',
                                }),
                            }}
                        />

                        <div className='row my-2 g-3'>
                            {LoadingTrending ? <button type='button' className='btn mainBtn col-12 rounded-pill  '><i className='fa fa-spinner fa-spin'></i></button>
                                : <button disabled={!(formik4.isValid && formik4.dirty)} type='submit' className='btn  rounded-pill  mainBtn col-12 '>Select</button>
                            }
                            <button onClick={() => { setTrendingMode(false) }} type='reset' className='btn mx-auto btn-outline-danger col-12 rounded-pill'>cancel</button>

                        </div>
                    </form>
                </div>
            </div>
            : null}

        <div className='container pt-5 bg-white my-5 shadow-lg'>
            <h2 className='text-center mainFont h1'>Channels</h2>

            <div className='p-5 '>
                <button onClick={() => {
                    getChannelCategory()
                    setAddMode(true)
                }} className='btn mainBtn rounded-pill my-2 mx-auto col-12  '>Add Channel</button>
                <button onClick={() => {
                    setTrendingMode(true)
                }} className='btn mainBtn rounded-pill my-2 mx-auto col-12  '>Trending Channels</button>
                {LoadingChannels ? <div className='col-12 text-center my-5 py-5'>
                    <i className='fa fa-spin fa-spinner fa-3x text-success'></i>
                </div> : <>
                    {Channels?.length != 0 ? <div className='table-responsive py-5'>
                        <div className="py-5">
                            <table class="table table-striped  table-hover mx-auto text-center my-5 ">
                                <thead >
                                    <tr >
                                        <th scope="col" className='mainFont' >#</th>
                                        <th scope="col" className='mainFont'>name</th>
                                        <th scope="col" className='mainFont'>lang</th>
                                        <th scope="col" className='mainFont'>category</th>
                                        <th scope="col" className='mainFont'>trending</th>

                                        <th scope="col" className='mainFont'>Acthions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {Channels?.map((channel, index) => {
                                        return <tr className='align-baseline'>
                                            <th scope="row" className='mainFont'>{index + 1}</th>
                                            <td className='mainFont'>{channel.title}</td>
                                            <td className='mainFont'>{channel.lang}</td>
                                            <td className='mainFont'>{channel.category.title}</td>
                                            {channel.trending ? <td className='mainFont'><span class="badge text-bg-success">True</span></td>
                                                : <td className='mainFont'><span class="badge text-bg-danger">False</span></td>}
                                            <td>
                                                <div class="dropdown">
                                                    <button class="btn mainIcon  " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="fa-solid fa-list fa-0 mainFont"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li onClick={() => { deleteChannel(channel._id) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-trash-can me-2"></i>delete</li>
                                                        <li onClick={() => { handelUpdatedData(channel) }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update</li>
                                                        <li onClick={() => {
                                                            formik3.setFieldValue("_id", channel._id)
                                                            setUpdateImageMode(true)
                                                        }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-pen-to-square me-2"></i>update Image</li>

                                                        <li onClick={() => {
                                                            setChannelData(channel)
                                                            console.log(channel);
                                                            setDetailsMode(true)
                                                        }} className="dropdown-item mainFont mainClick"><i class="fa-regular fa-eye me-2"></i>Details</li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div> : <div className='col-12 text-center my-5 py-5'>
                        <h3 className='mainFont'>Don't have Channels</h3>

                    </div>}


                </>
                }
            </div>

        </div>

    </>
}
